<template>
  <div class="select-map" style="border: solid #fff 10px;">
    <!-- 风险管控清单dig -->
    <el-dialog
      title="风险管控清单"
      class="add"
      custom-class="d"
      :visible.sync="listDialogVisible"
      width="70vw"
      @close="closeDialog"
    >
      <div class="handleLog">
        <!-- <el-row class="selectRow">
          <div class="selectCol">
            <span>风险分级</span>
            <el-select v-model="value3" placeholder="请选择">
              <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </div>
          <div class="selectCol">
            <span>风险点名称</span>
            <el-input v-model="danName" placeholder="请输入内容"></el-input>
          </div>
          <el-button @click="handleSearch" class="checkOutBtn">筛选</el-button>
        </el-row>-->
        <el-row class="tableRow">
          <el-table border :data="tableData" stripe style="width: 100%">
            <el-table-column align="center" prop="id" label="编号"></el-table-column>
            <el-table-column align="center" prop="company_name" label="企业名称" width="200">
              <template slot-scope="scope">
                <span
                  :title="scope.row.company_name"
                  class="overflowDoit"
                >{{scope.row.company_name}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="danger_name" label="风险点名称">
              <template slot-scope="scope">
                <span :title="scope.row.danger_name" class="overflowDoit">{{scope.row.danger_name}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="danger_accident" label="易发生事故类型" width="300">
              <template slot-scope="scope">
                <span
                  :title="scope.row.danger_accident"
                  class="overflowDoit"
                >{{scope.row.danger_accident}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="风险分级">
              <template slot-scope="scope">
                <span v-if="scope.row.danger_level==1" style="color:blue">低风险</span>
                <span v-if="scope.row.danger_level==2" style="color:#e7ea45">一般风险</span>
                <span v-if="scope.row.danger_level==3" style="color:#f9972f">较大风险</span>
                <span v-if="scope.row.danger_level==4" style="color:red">重大风险</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="position_id" label="部门">
              <template slot-scope="scope">
                <span :title="scope.row.position_id" class="overflowDoit">{{scope.row.position_id}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="负责人(联系方式)">
              <template slot-scope="scope">
                <span class="overflowDoit" :title="scope.row.charge_userid+'-'+scope.row.username">
                  <span
                    v-if="scope.row.charge_userid!='无'||scope.row.username!='无'"
                  >{{scope.row.charge_userid+'-'+scope.row.username}}</span>
                  <span v-if="scope.row.charge_userid=='无'&&scope.row.username=='无'">无</span>
                </span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="160">
              <template slot-scope="scope">
                <el-button @click="checkOut(scope.row)" class="tableBtn modifyBtn">查看详情</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            v-if="tableData.length>0"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[3, 5, 10, 20]"
            :page-size="10"
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalCount"
          ></el-pagination>
        </el-row>
      </div>
    </el-dialog>
    <el-dialog
      title="设备设施清单"
      class="add"
      custom-class="d"
      :visible.sync="eqListDialogVisible"
      width="70vw"
      @close="eqcloseDialog"
    >
      <div class="handleLog">
        <!-- <el-row class="selectRow">
          <div class="selectCol">
            <span>企业名称</span>
            <el-input v-model="companyName" placeholder="请输入内容"></el-input>
          </div>
          <div class="selectCol">
            <span>设备设施名称</span>
            <el-input v-model="eqName" placeholder="请输入内容"></el-input>
          </div>
          <div class="selectCol">
            <span>所属平台</span>
            <el-select clearable v-model="platId" placeholder="请选择">
              <el-option v-for="item in platList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </div>
          <el-button @click="handleSearch" class="checkOutBtn">筛选</el-button>
        </el-row>-->
        <el-row class="tableRow">
          <el-table border :data="tableData" stripe style="width: 100%">
            <el-table-column align="center" prop="id" label="编号" width="100"></el-table-column>
            <el-table-column align="center" prop="company_name" label="企业名称">
              <template slot-scope="scope">
                <span
                  :title="scope.row.company_name"
                  class="overflowDoit"
                >{{scope.row.company_name}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="danger_name" label="设备设施名称">
              <template slot-scope="scope">
                <span :title="scope.row.danger_name" class="overflowDoit">{{scope.row.danger_name}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="count" label="数量" width="160">
              <template slot-scope="scope">
                <span :title="scope.row.count" class="overflowDoit">{{scope.row.count}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="danger_remarks" label="备注">
              <template slot-scope="scope">
                <span
                  :title="scope.row.danger_remarks"
                  class="overflowDoit"
                >{{scope.row.danger_remarks}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="160">
              <template slot-scope="scope">
                <el-button @click="checkOut1(scope.row)" class="tableBtn modifyBtn">查看详情</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            v-if="tableData.length>0"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[3, 5, 10, 15, 20]"
            :page-size="pageSize"
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalCount"
          ></el-pagination>
        </el-row>
      </div>
    </el-dialog>
    <el-dialog
      title="作业活动清单"
      class="add"
      custom-class="d"
      width="70vw"
      :visible.sync="WorkListDialogVisible"
    >
      <div class="handleLog">
        <el-row class="tableRow">
          <el-table border :data="tableData4" stripe style="width: 100%">
            <el-table-column align="center" prop="id" label="编号" width="80"></el-table-column>
            <el-table-column align="center" prop="company_name" label="企业名称">
              <template slot-scope="scope">
                <span
                  :title="scope.row.company_name"
                  class="overflowDoit"
                >{{scope.row.company_name}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="danger_accident" label="作业活动">
              <template slot-scope="scope">
                <span
                  :title="scope.row.danger_accident"
                  class="overflowDoit"
                >{{scope.row.danger_accident}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="danger_name" label="作业区域">
              <template slot-scope="scope">
                <span :title="scope.row.danger_name" class="overflowDoit">{{scope.row.danger_name}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="type" label="作业类别">
              <template slot-scope="scope">
                <span :title="scope.row.type" class="overflowDoit">{{scope.row.type}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="danger_remarks" label="备注">
              <template slot-scope="scope">
                <span
                  :title="scope.row.danger_remarks"
                  class="overflowDoit"
                >{{scope.row.danger_remarks}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="160">
              <template slot-scope="scope">
                <el-button
                  style="margin:0 auto!important;"
                  @click="checkOutWork(scope.row)"
                  class="tableBtnRisk modifyBtn"
                >查看详情</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            v-if="tableData4.length>0"
            @size-change="handleSizeChange4"
            @current-change="handleCurrentChange4"
            :current-page="currentPage4"
            :page-sizes="[3, 5, 10,15, 20]"
            :page-size="pageSize4"
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalCount4"
          ></el-pagination>
        </el-row>
      </div>
    </el-dialog>
    <el-dialog
      title="隐患排查清单"
      class="add"
      custom-class="d"
      width="70vw"
      :visible.sync="DanListDialogVisible"
    >
      <div class="handleLog">
        <el-row class="tableRow">
          <el-table border :data="tableData6" stripe style="width: 100%">
            <el-table-column align="center" prop="id" label="编号" width="80"></el-table-column>
            <el-table-column align="center" prop="company_name" label="企业名称">
              <template slot-scope="scope">
                <span
                  :title="scope.row.company_name"
                  class="overflowDoit"
                >{{scope.row.company_name}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="danger_name" label="风险点名称">
              <template slot-scope="scope">
                <span :title="scope.row.danger_name" class="overflowDoit">{{scope.row.danger_name}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="danger_accident" label="潜在事故类型" width="250">
              <template slot-scope="scope">
                <span
                  :title="scope.row.danger_accident"
                  class="overflowDoit"
                >{{scope.row.danger_accident}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="reason" label="事故原因分析" width="150">
              <template slot-scope="scope">
                <span :title="scope.row.reason" class="overflowDoit cursor">{{scope.row.reason}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="danger_measures" label="已有控制措施" width="300">
              <template slot-scope="scope">
                <span
                  :title="scope.row.danger_measures"
                  class="overflowDoit cursor"
                >{{scope.row.danger_measures}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="danger_level" label="风险分级">
              <template slot-scope="scope">
                <span v-if="scope.row.danger_level==1" style="color:blue">低风险</span>
                <span v-if="scope.row.danger_level==2" style="color:#e7ea45">一般风险</span>
                <span v-if="scope.row.danger_level==3" style="color:#f9972f">较大风险</span>
                <span v-if="scope.row.danger_level==4" style="color:red">重大风险</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="150">
              <template slot-scope="scope">
                <el-button @click="handleDangerCheck(scope.row)" class="tableBtnRisk modifyBtn">查看详情</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            v-if="tableData6.length>0"
            @size-change="handleSizeChange6"
            @current-change="handleCurrentChange6"
            :current-page="currentPage6"
            :page-sizes="[3, 5, 10,15, 20]"
            :page-size="pageSize6"
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalCount6"
          ></el-pagination>
        </el-row>
      </div>
    </el-dialog>
    <el-dialog
      title="巡检记录"
      class="add"
      custom-class="d"
      width="70vw"
      :visible.sync="XunListDialogVisible"
    >
      <div class="handleLog">
        <el-row class="tableRow">
          <el-table border :data="tableData7" stripe style="width: 100%">
            <el-table-column align="center" prop="id" label="编号" width="80"></el-table-column>
            <el-table-column align="center" prop="company_name" label="企业名称">
              <template slot-scope="scope">
                <span
                  :title="scope.row.company_name"
                  class="overflowDoit"
                >{{scope.row.company_name}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="danger_name" label="风险点名称">
              <template slot-scope="scope">
                <span :title="scope.row.danger_name" class="overflowDoit">{{scope.row.danger_name}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="safety_inspection_name" label="班次名称">
              <template slot-scope="scope">
                <span
                  :title="scope.row.safety_inspection_name"
                  class="overflowDoit"
                >{{scope.row.safety_inspection_name}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="user_name" label="巡检人">
              <template slot-scope="scope">
                <span :title="scope.row.user_name" class="overflowDoit">{{scope.row.user_name}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="巡检状态">
              <template slot-scope="scope">
                <span style="color:#4e89db" v-if="scope.row.status==1">正常</span>
                <span style="color:#e84649" v-if="scope.row.status==2">异常</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="add_time" label="巡检时间">
              <template slot-scope="scope">
                <span :title="scope.row.add_time" class="overflowDoit">{{scope.row.add_time}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="inspection_status_show" label="整改状态">
              <template slot-scope="scope">
                <span
                  :title="scope.row.inspection_status_show"
                  class="overflowDoit"
                >{{scope.row.inspection_status_show}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="240">
              <template slot-scope="scope">
                <div style="display:flex">
                  <el-button
                    @click="checkOutXun(scope.row)"
                    style="height:30px;padding:0;margin:0"
                    class="tableBtn modifyBtn"
                  >巡检详情</el-button>
                  <el-button
                    @click="changeHistory(scope.row.id)"
                    style="height:30px;padding:0;margin:0"
                    class="tableBtn modifyBtn"
                  >整改记录</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            v-if="tableData7.length>0"
            @size-change="handleSizeChange7"
            @current-change="handleCurrentChange7"
            :current-page="currentPage7"
            :page-sizes="[3, 5, 10,15, 20]"
            :page-size="pageSize7"
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalCount7"
          ></el-pagination>
        </el-row>
      </div>
    </el-dialog>
    <!-- 企业详情dig -->
    <el-dialog title :visible.sync="dialogVisible" top="30vh" width="30%">
      <div class="dialog_border">
        <el-row :gutter="50">
          <div style="font-size: 18px;">企业信息</div>
          <el-col :span="24">
            企业名称：
            <span class="el_span">{{com_name}}</span>
          </el-col>
          <el-col :span="24">
            企业详细地址：
            <span class="el_span">{{com_add}}</span>
          </el-col>
          <el-col :span="24">
            企业负责人：
            <span class="el_span">{{com_charge_name}}</span>
          </el-col>
          <el-col :span="24">
            电话：
            <span class="el_span">{{com_charge_mobile}}</span>
            <!-- <span>安全负责人：{{com_safe_name}}</span> <span class="el_span">电话：{{com_safe_mobile}}</span> -->
          </el-col>
        </el-row>
        <div style="text-align: center; " v-show="divShow">
          <div
            class
            style="display: flex; width: 97%; height: 40px; background-color: #e1e1e1; border-radius: 50px; justify-content: space-between;"
          >
            <el-button
              class="mt_30"
              type="primary"
              round
              size="medium"
              @click="imgClick"
              v-show="imgShowL"
            >四色图</el-button>

            <el-button
              class="mt_30"
              type="danger"
              round
              size="medium"
              @click="videoClick()"
              v-show="videoShowL"
            >视频</el-button>
          </div>
        </div>
        <el-row :gutter="50">
          <div style="font-size: 18px;">安全信息</div>
          <el-col :span="24" style>
            <!-- <span>安全负责人：{{com_safe_name}}</span> <span class="el_span">电话：{{com_safe_mobile}}</span> -->
          </el-col>
          <el-col :span="24">
            <span>风险管控清单：</span>
          </el-col>
          <el-col :span="24" style="margin-top: -10px; ">
            <span>
              <span style="color: #ff0000; font-weight: 700;">Ⅰ级:{{count_great}}处</span>
              <span style="color: #ff5500; font-weight: 700;">Ⅱ级:{{count_high}}处</span>
              <span style="color: #ffaa00; font-weight: 700;">Ⅲ级:{{count_general}}处</span>
              <span style="color: #00aaff; font-weight: 700;">Ⅳ级:{{count_low}}处</span>
            </span>
            <span class="el_span">
              <el-button
                style="height: 10px;line-height: 1px;"
                class="denger_button"
                round
                size="mini"
                type="primary"
                @click="dangerList"
              >查看</el-button>
            </span>
          </el-col>
          <el-col :span="24">
            <span>设备设施清单：</span>
            <span class="el_span">
              <el-button
                style="height: 10px;line-height: 1px;"
                class="denger_button"
                round
                size="mini"
                type="primary"
                @click="equDangerList"
              >查看</el-button>
            </span>
          </el-col>
          <el-col :span="24">
            <span>作业活动清单：</span>
            <span class="el_span">
              <el-button
                style="height: 10px;line-height: 1px;"
                class="denger_button"
                round
                size="mini"
                type="primary"
                @click="workDangerList"
              >查看</el-button>
            </span>
          </el-col>
          <el-col :span="24">
            <span>隐患排查清单：</span>
            <span class="el_span">
              <el-button
                style="height: 10px;line-height: 1px;"
                class="denger_button"
                round
                size="mini"
                type="primary"
                @click="HiddenDangerList"
              >查看</el-button>
            </span>
          </el-col>

          <el-col :span="24">
            <span>巡检记录：</span>
            <span class="el_span">
              <el-button
                style="height: 10px;line-height: 1px;"
                class="denger_button"
                round
                size="mini"
                type="primary"
                @click="XunDangerList"
              >查看</el-button>
            </span>
          </el-col>
          <el-col :span="24">
            <span>企业风险点数量：</span>
            <span class="el_span">{{danger_count}}</span>
          </el-col>
          <el-col :span="24">
            <span>企业应巡检总次数：</span>
            <span class="el_span">{{inspection_total}}</span>
          </el-col>
          <el-col :span="24">
            <span>企业巡检次数：</span>
            <span class="el_span">{{inspection_num}}</span>
          </el-col>
          <el-col :span="24">
            <span>企业巡查率：</span>
            <span class="el_span">{{inspectionone_precent}}%</span>
          </el-col>
        </el-row>

        <!-- <span slot="footer" class="dialog-footer" >
        <el-button type="primary" @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>-->
      </div>
    </el-dialog>
    <!-- 风险管控详情dia开始 -->
    <!-- :title="`风险点辨识详情(${userName}-${dangerName})`" -->
    <el-dialog
      :title="`风险点辨识详情(${dangerName})`"
      class="riskDia"
      :visible.sync="dialogDetail"
      width="70%"
      :before-close="dialogDetailClose"
    >
      <el-row class="tableRow">
        <el-form ref="form" :model="formData" label-width="160px">
          <el-form-item label="风险点名称">
            <el-input style="width:70%" disabled v-model="formData.danger_name"></el-input>
          </el-form-item>
          <el-form-item label="风险分级">
            <el-input style="width:70%" disabled v-model="formData.danger_level"></el-input>
          </el-form-item>
          <el-form-item label="所在区域">
            <el-input style="width:70%" disabled v-model="formData.danger_position"></el-input>
          </el-form-item>
          <el-form-item label="易发生事故类型" prop="desc">
            <el-input
              class="modifyInner"
              type="textarea"
              style="width:88%"
              disabled
              v-model="formData.danger_accident"
            ></el-input>
          </el-form-item>
          <el-form-item label="主要危险有害性" prop="desc">
            <el-input
              class="modifyInner"
              style="width:88%"
              type="textarea"
              disabled
              v-model="formData.reason"
            ></el-input>
          </el-form-item>
          <el-form-item label="措施" prop="desc">
            <el-input
              class="modifyInner"
              type="textarea"
              style="width:88%"
              disabled
              v-model="formData.danger_measures"
            ></el-input>
          </el-form-item>
          <el-form-item label="应急处置对策" prop="desc">
            <el-input
              class="modifyInner"
              style="width:88%"
              type="textarea"
              disabled
              v-model="formData.change_measures"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-row>
    </el-dialog>
    <!-- 设备设施详情dia开始 -->
    <!-- :title="`设备设施清单详情(${userName}-${dangerName})`" -->
    <el-dialog
      :title="`设备设施清单详情(${dangerName})`"
      class="riskDia"
      :visible.sync="eqListDialogVisible_D"
      width="70%"
      :before-close="eqdialogDetailClose"
    >
      <el-row class="tableRow">
        <el-form ref="form" :model="formData" label-width="180px">
          <el-form-item label="风险点名称">
            <el-input style="width:70%" disabled v-model="formData1.danger_name"></el-input>
          </el-form-item>
          <el-form-item label="型号规格">
            <el-input style="width:70%" disabled v-model="formData1.model"></el-input>
          </el-form-item>
          <el-form-item label="主要物料">
            <el-input style="width:70%" disabled v-model="formData1.main_materials"></el-input>
          </el-form-item>
          <el-form-item label="相态">
            <el-input style="width:70%" disabled v-model="formData1.phase_state"></el-input>
          </el-form-item>
          <el-form-item label="温度/℃">
            <el-input style="width:70%" disabled v-model="formData1.temperature"></el-input>
          </el-form-item>
          <el-form-item label="压力/Mpa">
            <el-input style="width:70%" disabled v-model="formData1.pressure"></el-input>
          </el-form-item>
          <el-form-item label="体积/立方米">
            <el-input style="width:70%" disabled v-model="formData1.volume"></el-input>
          </el-form-item>
          <el-form-item label="浓度/V%或W%">
            <el-input style="width:70%" disabled v-model="formData1.concentration"></el-input>
          </el-form-item>
          <el-form-item label="危险物质储存量或滞留量/kg">
            <el-input style="width:70%" disabled v-model="formData1.danger_capacity"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="desc">
            <el-input
              class="modifyInner"
              type="textarea"
              disabled
              style="width:88%"
              v-model="formData1.danger_remarks"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-row>
    </el-dialog>
    <!-- 设备设施详情dia结束 -->
    <!-- :title="`作业活动清单详情(${userName}-${workName})`" -->
    <el-dialog
      :title="`作业活动清单详情(${workName})`"
      class="riskDia"
      :visible.sync="workDetail"
      width="70%"
      :before-close="workDetailClose"
    >
      <el-row class="tableRow">
        <el-form ref="form" :model="formData4" label-width="160px">
          <el-form-item label="作业活动">
            <el-input style="width:70%" disabled v-model="formData4.danger_accident"></el-input>
          </el-form-item>
          <el-form-item label="作业区域">
            <el-input style="width:70%" disabled v-model="formData4.danger_name"></el-input>
          </el-form-item>
          <el-form-item label="作业类别">
            <el-input style="width:70%" disabled v-model="formData4.type"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="desc">
            <el-input
              class="modifyInner"
              type="textarea"
              disabled
              style="width:88%"
              v-model="formData4.danger_remarks"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-row>
    </el-dialog>
    <!-- 作业活动风险评价详情查看dia开始 -->
    <!-- :title="`作业活动风险评价详情(${userName}-${dangerName1})`" -->
    <el-dialog
      :title="`隐患排查清单详情(${dangerName1})`"
      class="riskDia"
      :visible.sync="dangerCheckDetail"
      width="70%"
      :before-close="dangerCheckDetailClose"
    >
      <el-row class="tableRow">
        <el-form ref="form" :model="formData6" label-width="160px">
          <el-form-item label="风险点名称">
            <el-input style="width:70%" disabled v-model="formData6.danger_name"></el-input>
          </el-form-item>
          <el-form-item label="风险分级">
            <el-input style="width:70%" disabled v-model="formData6.danger_level_show"></el-input>
          </el-form-item>
          <el-form-item label="工作内容" prop="desc">
            <el-input
              class="modifyInner"
              type="textarea"
              style="width:88%"
              disabled
              v-model="formData6.danger_remarks"
            ></el-input>
          </el-form-item>
          <el-form-item label="矫正措施" prop="desc">
            <el-input
              class="modifyInner"
              style="width:88%"
              type="textarea"
              disabled
              v-model="formData6.change_measures"
            ></el-input>
          </el-form-item>
          <el-form-item label="潜在事故类型" prop="desc">
            <el-input
              style="width:88%"
              class="modifyInner"
              type="textarea"
              disabled
              v-model="formData6.danger_accident"
            ></el-input>
          </el-form-item>
          <el-form-item label="已有控制措施" prop="desc">
            <el-input
              class="modifyInner"
              style="width:88%"
              type="textarea"
              disabled
              v-model="formData6.danger_measures"
            ></el-input>
          </el-form-item>
          <el-form-item label="事故原因分析" prop="desc">
            <el-input
              class="modifyInner"
              style="width:88%"
              type="textarea"
              disabled
              v-model="formData6.reason"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-row>
    </el-dialog>

    <!-- :title="`巡检记录巡检详情(${userName})`" -->
    <el-dialog
      :title="`巡检记录巡检详情`"
      class="riskDia"
      :visible.sync="dialogDetailXun"
      width="50%"
      :before-close="dialogDetailXunClose"
    >
      <div class="handleLog">
        <el-row class="tableRow">
          <el-form ref="form" :model="formDataXun" label-width="120px">
            <el-form-item label="风险点名称">
              <el-input style="width:70%" disabled v-model="formDataXun.danger_name"></el-input>
            </el-form-item>
            <el-form-item label="班次名称">
              <el-input style="width:70%" disabled v-model="formDataXun.safety_inspection_name"></el-input>
            </el-form-item>
            <el-form-item label="巡检人">
              <el-input style="width:70%" disabled v-model="formDataXun.user_name"></el-input>
            </el-form-item>
            <el-form-item label="巡检状态">
              <el-input
                style="width:70%"
                :class="formDataXun.status_show == '正常' ?'isBlue':'isRed'"
                disabled
                v-model="formDataXun.status_show"
              ></el-input>
            </el-form-item>
            <el-form-item label="巡检备注" prop="desc">
              <el-input
                class="modifyInner"
                style="width:88%"
                type="textarea"
                disabled
                v-model="formDataXun.remark"
              ></el-input>
            </el-form-item>
            <el-form-item label="巡检图片或视频" prop="desc" style="padding-top:10px;">
              <img
                v-if="detailImgList.length!=0"
                v-for="item in detailImgList"
                @click="showPic(item)"
                style="width: 220px;height: 130px; margin-right:10px;"
                :src="item"
                alt
              />
              <img
                v-if="detailImgList.length==0"
                style="width: 220px;height: 130px; margin-right:10px;"
                src="../../assets/images/noPic.png"
                alt
              />
              <!-- controls="controls" -->
              <video
                v-if="detailVideoList.length>0"
                v-for="item in detailVideoList"
                style="width: 220px;height: 130px; object-fit:fill"
                :src="item"
                controls
                autoplay
                @click="showVideo(item)"
              >
                您的浏览器不支持
                video 标签。
              </video>
              <img
                v-if="detailVideoList.length==0"
                style="width: 220px;height: 130px; margin-right:10px;"
                src="../../assets/images/noVid.png"
                alt
              />
            </el-form-item>
          </el-form>
        </el-row>
      </div>
    </el-dialog>
    <!-- 巡检详情dia结束 -->

    <!-- 整改记录列表开始 -->
    <!-- :title="`整改记录(${userName})`" -->

    <el-dialog
      :title="`整改记录`"
      class="riskDia"
      :visible.sync="dialogHistory"
      width="80%"
      :before-close="historyDiaClose"
    >
      <div class="handleLog">
        <el-row class="tableRow">
          <el-table border :data="tableDataZheng" stripe style="width: 100%">
            <el-table-column align="center" prop="id" label="编号" width="80">
              <template slot-scope="scope">
                <span :title="scope.row.id" class="overflowDoit">{{scope.row.id}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="assign_username" label="指派人">
              <template slot-scope="scope">
                <span
                  :title="scope.row.assign_username"
                  class="overflowDoit"
                >{{scope.row.assign_username}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="rectify_username" label="整改人">
              <template slot-scope="scope">
                <span
                  :title="scope.row.rectify_username"
                  class="overflowDoit"
                >{{scope.row.rectify_username}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="review_username" label="复查人">
              <template slot-scope="scope">
                <span
                  :title="scope.row.review_username"
                  class="overflowDoit"
                >{{scope.row.review_username}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="status_show" label="整改状态">
              <template slot-scope="scope">
                <span :title="scope.row.status_show" class="overflowDoit">{{scope.row.status_show}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="rectify_overtime" label="整改规定时间">
              <template slot-scope="scope">
                <span
                  :title="scope.row.rectify_overtime"
                  class="overflowDoit"
                >{{scope.row.rectify_overtime}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                <el-button @click="checkOutDetails(scope.row.id)" class="tableBtnRisk">查看详情</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            v-if="tableDataZheng.length>0"
            @size-change="handleSizeChangeZheng"
            @current-change="handleCurrentChangeZheng"
            :current-page="currentPageZheng"
            :page-sizes="[3, 5, 10,15, 20]"
            :page-size="pageSizeZheng"
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalCountZheng"
          ></el-pagination>
        </el-row>
      </div>
    </el-dialog>
    <!-- 整改记录dia结束 -->
    <el-dialog
      :title="`整改记录详情`"
      class="riskDia checkDetails"
      :visible.sync="dialogdh"
      width="50%"
      :before-close="dialogdhClose"
    >
      <div class="handleLog">
        <el-row class="tableRow">
          <el-form ref="form" :model="formDetailsZheng" label-width="160px">
            <el-form-item label="风险点名称">
              <el-input style="width:70%" disabled v-model="formDetailsZheng.danger_name"></el-input>
            </el-form-item>
            <el-form-item label="班次名称">
              <el-input
                style="width:70%"
                disabled
                v-model="formDetailsZheng.safety_inspection_name"
              ></el-input>
            </el-form-item>
            <el-form-item label="巡检人">
              <el-input style="width:70%" disabled v-model="formDetailsZheng.user_name"></el-input>
            </el-form-item>
            <el-form-item label="巡检状态">
              <el-input
                style="width:70%"
                :class="formDetailsZheng.status_show=='正常'?'isBlue':'isRed'"
                disabled
                v-model="formDetailsZheng.status_show"
              ></el-input>
            </el-form-item>
            <el-form-item label="巡检备注" prop="desc">
              <el-input
                class="modifyInner"
                type="textarea"
                style="width:88%"
                disabled
                v-model="formDetailsZheng.remark"
              ></el-input>
            </el-form-item>
            <el-form-item label="巡检图片或视频" prop="desc" style="padding-top:10px;">
              <img
                v-if="changeImgCheckList"
                v-for="item in changeImgCheckList"
                @click="showPic(item)"
                style="width: 220px;height: 130px; margin-right:10px;"
                :src="item"
                alt
              />
              <img
                v-if="changeImgCheckList.length==0"
                style="width: 220px;height: 130px; margin-right:10px;"
                src="../../assets/images/noPic.png"
                alt
              />
              <!-- controls="controls" -->
              <video
                v-if="changeVideoCheckList"
                v-for="item in changeVideoCheckList"
                controls
                autoplay
                style="width: 220px;height: 130px; object-fit:fill"
                :src="item"
                @click="showVideo(item)"
              >
                您的浏览器不支持
                video 标签。
              </video>
              <img
                v-if="changeVideoCheckList.length==0"
                style="width: 220px;height: 130px; margin-right:10px;"
                src="../../assets/images/noVid.png"
                alt
              />
            </el-form-item>
            <el-form-item label="巡检时间">
              <el-input style="width:70%" disabled v-model="formDetailsZheng.add_time"></el-input>
            </el-form-item>
            <el-form-item label="整改指派人">
              <el-input style="width:70%" disabled v-model="formDetailsZheng.assign_username"></el-input>
            </el-form-item>
            <el-form-item label="指派完成时间">
              <el-input style="width:70%" disabled v-model="formDetailsZheng.rectify_overtime"></el-input>
            </el-form-item>
            <el-form-item label="整改人">
              <el-input style="width:70%" disabled v-model="formDetailsZheng.rectify_username"></el-input>
            </el-form-item>
            <el-form-item label="整改时间">
              <el-input style="width:70%" disabled v-model="formDetailsZheng.rectify_time"></el-input>
            </el-form-item>
            <el-form-item label="整改备注" prop="desc">
              <el-input
                class="modifyInner"
                type="textarea"
                style="width:88%"
                disabled
                v-model="formDetailsZheng.rectify_remarks"
              ></el-input>
            </el-form-item>
            <el-form-item label="整改图片或视频" prop="desc" style="padding-top:10px;">
              <img
                v-if="changeImgList.length>0"
                v-for="item in changeImgList"
                @click="showPic(item)"
                style="width: 220px;height: 130px; margin-right:10px;"
                :src="item"
                alt
              />
              <img
                v-if="changeImgList.length==0"
                style="width: 220px;height: 130px; margin-right:10px;"
                src="../../assets/images/noPic.png"
                alt
              />
              <!-- controls="controls" -->
              <video
                v-if="changeVideoResetList.length>0"
                v-for="item in changeVideoResetList"
                style="width: 220px;height: 130px; object-fit:fill"
                :src="item"
                controls
                autoplay
                @click="showVideo(item)"
              >
                您的浏览器不支持
                video 标签。
              </video>
              <img
                v-if="changeVideoCheckList.length==0"
                style="width: 220px;height: 130px; margin-right:10px;"
                src="../../assets/images/noVid.png"
                alt
              />
            </el-form-item>
            <el-form-item label="复查人">
              <el-input style="width:70%" disabled v-model="formDetailsZheng.review_username"></el-input>
            </el-form-item>
            <el-form-item label="复查时间">
              <el-input style="width:70%" disabled v-model="formDetailsZheng.review_time"></el-input>
            </el-form-item>
            <el-form-item label="复查备注" prop="desc">
              <el-input
                class="modifyInner"
                type="textarea"
                style="width:88%"
                disabled
                v-model="formDetailsZheng.review_remarks"
              ></el-input>
            </el-form-item>
            <el-form-item label="复查图片或视频" prop="desc" style="padding-top:10px;">
              <img
                v-if="changeImgResetList.length>0"
                v-for="item in changeImgResetList"
                @click="showPic(item)"
                style="width: 220px;height: 130px; margin-right:10px;"
                :src="item"
                alt
              />
              <img
                v-if="changeImgResetList.length==0"
                style="width: 220px;height: 130px; margin-right:10px;"
                src="../../assets/images/noPic.png"
                alt
              />
              <!-- controls="controls" -->
              <video
                v-if="changeVideoResetList.length>0"
                v-for="item in changeVideoResetList"
                style="width: 220px;height: 130px; object-fit:fill"
                :src="item"
                controls
                autoplay
                @click="showVideo(item)"
              >
                您的浏览器不支持
                video 标签。
              </video>
              <img
                v-if="changeVideoCheckList.length==0"
                style="width: 220px;height: 130px; margin-right:10px;"
                src="../../assets/images/noVid.png"
                alt
              />
            </el-form-item>
          </el-form>
        </el-row>
      </div>
    </el-dialog>
    <!-- 图片dia开始 -->
    <el-dialog
      class="riskDia imgDia"
      v-if="imgDia"
      :visible.sync="imgDia"
      width="70vw"
      :before-close="imgDiaClose"
    >
      <el-row class="tableRow">
        <img :src="showImgUrl" alt />
      </el-row>
    </el-dialog>
    <!-- 四色图 -->
    <el-dialog title="企业风险四色图" :visible.sync="imgDialogVisible" width="800px">
      <!-- :before-close="handleClose" -->
      <span>
        <div class="demo-image">
          <div class="block" v-for="fit in fits" :key="fit">
            <!-- <span class="demonstration">企业风险</span> -->
            <el-image style="width: 500px; height: 500px" :src="url" :fit="fit"></el-image>
          </div>
        </div>
      </span>
      <span slot="footer" class="dialog-footer"></span>
    </el-dialog>

    <el-dialog
      title="视频实时监控"
      :visible.sync="videoDialog"
      v-if="videoDialog"
      width="80%"
      @close="closeDialog_Video()"
    >
      <el-row class="tableRow">
        <div class="videoContainer">
          <div class="videoItem" v-for="item,index in videoShow">
            <p>{{videoShowName[index]}}</p>
            <video
              :id="'myVideo'+index"
              style="width: 100%;height:100%"
              class="video-js vjs-default-skin box"
              controls
              preload="auto"
            >
              <source :src="item" type="application/x-mpegURL" />
            </video>
          </div>
        </div>
        <img v-if="videoShow.length==0" style="width:100%" src="../../assets/images/noVid.png" alt />
      </el-row>
    </el-dialog>
    <!-- needTodo -->
    <el-dialog
      title="首页预警"
      class="needToDodia"
      :visible.sync="needTodoDia"
      width="80%"
      :before-close="needTodoClose"
    >
      <el-row class="tableRow">
        <el-tabs v-model="activeNameTodo" @tab-click="handleTodoClick">
          <el-tab-pane :label="'待指派('+ zpnum +')'" name="first">
            <el-table :data="todoList" stripe border style="width: 100%">
              <el-table-column align="center" prop="add_time" label="巡检时间">
                <template slot-scope="scope">
                  <span :title="scope.row.add_time" class="overflowDoit">{{scope.row.add_time}}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="company_name" label="企业名称">
                <template slot-scope="scope">
                  <span
                    :title="scope.row.company_name"
                    class="overflowDoit"
                  >{{scope.row.company_name}}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" width="300" label="风险点名称">
                <template slot-scope="scope">
                  <span
                    :title="scope.row.danger_name"
                    class="overflowDoit"
                  >{{scope.row.danger_name}}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" width="100" label="风险分级">
                <template slot-scope="scope">
                  <span v-if="scope.row.danger_level==1" style="color:blue">低风险</span>
                  <span v-if="scope.row.danger_level==2" style="color:#e7ea45">一般风险</span>
                  <span v-if="scope.row.danger_level==3" style="color:#f9972f">较大风险</span>
                  <span v-if="scope.row.danger_level==4" style="color:red">重大风险</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="班次名称">
                <template slot-scope="scope">
                  <span
                    :title="scope.row.inspection_name"
                    class="overflowDoit"
                  >{{scope.row.inspection_name}}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="负责人">
                <template slot-scope="scope">
                  <span
                    :title="scope.row.inspection_name"
                    class="overflowDoit"
                  >{{scope.row.username +'-' + scope.row.nickname}}</span>
                </template>
              </el-table-column>
            </el-table>
            <div class="pageRow" style="width:100%;text-align:right">
              <el-pagination
                @current-change="toDohandleCurrentChange"
                :current-page="todoCurrentPage"
                background
                layout="total, prev, pager, next, jumper"
                :total="todoTotal"
              ></el-pagination>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="'待整改('+ zgnum +')'" name="second">
            <el-table :data="todoList" stripe border style="width: 100%">
              <el-table-column align="center" prop="add_time" label="巡检时间">
                <template slot-scope="scope">
                  <span :title="scope.row.add_time" class="overflowDoit">{{scope.row.add_time}}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="company_name" label="企业名称">
                <template slot-scope="scope">
                  <span
                    :title="scope.row.company_name"
                    class="overflowDoit"
                  >{{scope.row.company_name}}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" width="300" label="风险点名称">
                <template slot-scope="scope">
                  <span
                    :title="scope.row.danger_name"
                    class="overflowDoit"
                  >{{scope.row.danger_name}}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" width="100" label="风险分级">
                <template slot-scope="scope">
                  <span v-if="scope.row.danger_level==1" style="color:blue">低风险</span>
                  <span v-if="scope.row.danger_level==2" style="color:#e7ea45">一般风险</span>
                  <span v-if="scope.row.danger_level==3" style="color:#f9972f">较大风险</span>
                  <span v-if="scope.row.danger_level==4" style="color:red">重大风险</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="班次名称">
                <template slot-scope="scope">
                  <span
                    :title="scope.row.inspection_name"
                    class="overflowDoit"
                  >{{scope.row.inspection_name}}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="负责人">
                <template slot-scope="scope">
                  <span
                    :title="scope.row.inspection_name"
                    class="overflowDoit"
                  >{{scope.row.username +'-' + scope.row.nickname}}</span>
                </template>
              </el-table-column>
            </el-table>
            <div class="pageRow" style="width:100%;text-align:right">
              <el-pagination
                @current-change="toDohandleCurrentChange"
                :current-page="todoCurrentPage"
                background
                layout="total, prev, pager, next, jumper"
                :total="todoTotal"
              ></el-pagination>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="'待复查('+ fcnum +')'" name="third">
            <el-table :data="todoList" stripe border style="width: 100%">
              <el-table-column align="center" prop="add_time" label="巡检时间">
                <template slot-scope="scope">
                  <span :title="scope.row.add_time" class="overflowDoit">{{scope.row.add_time}}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="company_name" label="企业名称">
                <template slot-scope="scope">
                  <span
                    :title="scope.row.company_name"
                    class="overflowDoit"
                  >{{scope.row.company_name}}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" width="300" label="风险点名称">
                <template slot-scope="scope">
                  <span
                    :title="scope.row.danger_name"
                    class="overflowDoit"
                  >{{scope.row.danger_name}}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" width="100" label="风险分级">
                <template slot-scope="scope">
                  <span v-if="scope.row.danger_level==1" style="color:blue">低风险</span>
                  <span v-if="scope.row.danger_level==2" style="color:#e7ea45">一般风险</span>
                  <span v-if="scope.row.danger_level==3" style="color:#f9972f">较大风险</span>
                  <span v-if="scope.row.danger_level==4" style="color:red">重大风险</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="班次名称">
                <template slot-scope="scope">
                  <span
                    :title="scope.row.inspection_name"
                    class="overflowDoit"
                  >{{scope.row.inspection_name}}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="负责人">
                <template slot-scope="scope">
                  <span
                    :title="scope.row.inspection_name"
                    class="overflowDoit"
                  >{{scope.row.username +'-' + scope.row.nickname}}</span>
                </template>
              </el-table-column>
            </el-table>
            <div class="pageRow" style="width:100%;text-align:right">
              <el-pagination
                @current-change="toDohandleCurrentChange"
                :current-page="todoCurrentPage"
                background
                layout="total, prev, pager, next, jumper"
                :total="todoTotal"
              ></el-pagination>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-row>
    </el-dialog>
    <!-- needTodo -->

    <div>
      <!-- 顶部搜索栏 -->
      <div class="bt_father">
        <div class="navSelect">
          <span style="font-size: 10px;">监管行业</span>
          <el-select v-model="value" size="mini" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <span style="font-size: 10px; margin-left: 30px;">风险等级</span>
          <el-select v-model="value1" size="mini" placeholder="请选择">
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <span style="font-size: 10px; margin-left: 30px;">企业名称</span>
          <el-input v-model="input" placeholder="请输入企业名称" size="mini" style="width: 200px;"></el-input>
          <el-button
            style="margin-left: 30px;"
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="getCompanyList"
          >查询</el-button>
        </div>
        <div class="list_position">
          <!-- <button class="btn_list" v-for="item in company" @click="listClick(item.lng,item.lat,item.id)" style="">
          {{item.nickname}}</button>-->
          <button class="btn_list">单位总数量 {{count}}个</button>
          <button class="btn_list1">重大风险(Ⅰ级) 单位 {{level_great}}个</button>
          <button class="btn_list2">较大风险(Ⅱ级) 单位 {{level_high}}个</button>
          <button class="btn_list3">一般风险(Ⅲ级) 单位 {{level_general}}个</button>
          <button class="btn_list4">低风险(Ⅳ级) 单位 {{level_low}}个</button>
          <!-- <div class="input-item">
            <button id="zoomOn" class="btn" style="margin-right:1rem;">绑定事件</button>
            <button id="zoomOff" class="btn">解绑事件</button>
            <h4 id="text"></h4>
            <div class="info">
              <h4 id="text"></h4>
              <p>当前级别：<span id="map-zoom">15</span></p>
            </div>
            <div class="input-card" style="width:16rem"></div>
          </div>-->
        </div>
      </div>
      <!-- 顶部搜索栏end -->
    </div>

    <!-- 地图 -->
    <div id="mapInformation" class="ditu"></div>
  </div>
</template>
<!-- <script src="https://a.amap.com/jsapi_demos/static/demo-center/js/demoutils.js"></script> -->
<script>
import videojs from 'video.js'
import 'videojs-contrib-hls'
import { AMapManager } from 'vue-amap'
import { lazyAMapApiLoaderInstance } from 'vue-amap'
export default {
  data() {
    return {
      todoCurrentPage: 1,
      todoPageSize: 5,
      todoTotal: 0,
      toDoType: '1',
      activeNameTodo: 'first',
      todoList: [],
      zpnum: 0,
      zgnum: 0,
      fcnum: 0,
      needTodoDia: false,
      myVideo: [],
      fits: ['fill'],
      url: '', //四色图
      infp: '', //视频
      token: '',
      userName: '',
      zooms: '',
      map: '',
      center: [113.42946, 34.703061],
      district: '',
      loacation: '',
      company: '',
      dialogVisible: false,
      imgDialogVisible: false,
      videoDialog: false,
      listDialogVisible: false,
      eqListDialogVisible: false,
      eqListDialogVisible_D: false,
      WorkListDialogVisible: false,
      WorkListDialogVisible_D: false,
      DanListDialogVisible: false,
      DanListDialogVisible_D: false,
      XunListDialogVisible: false,
      XunListDialogVisible_D: false,
      workDetail: false, //作业活动详情
      com_name: '',
      com_add: '',
      com_charge_name: '', //主要负责人
      com_charge_mobile: '', //主要手机号
      com_safe_name: '',
      com_safe_mobile: '',
      id: '',
      is_mine: '',
      level: '', //企业风险级别
      danger_count: '', //企业风险点数量
      inspection_total: '', //企业应巡检总次数
      inspection_num: '', //企业巡检次数
      inspectionone_precent: '', //企业巡查率
      count: '',
      level_great: '',
      level_high: '',
      level_general: '',
      level_low: '',
      count_great: '',
      count_high: '',
      count_general: '',
      count_low: '',
      options: [
        {
          value: '',
          label: '全部',
        },
        {
          value: '矿山类',
          label: '矿山类',
        },
        {
          value: '工贸类',
          label: '工贸类',
        },
        {
          value: '化工类',
          label: '化工类',
        },
        {
          value: '社会服务类',
          label: '社会服务类',
        },
      ],
      options1: [
        {
          value: '',
          label: '全部',
        },
        {
          value: '1',
          label: '低风险',
        },
        {
          value: '2',
          label: '一般风险',
        },
        {
          value: '3',
          label: '较大风险',
        },
        {
          value: '4',
          label: '重大风险',
        },
      ],
      value: '',
      value1: '',
      input: '',
      platId: '',
      platList: [],
      userName: '',
      token: '',
      danName: '',
      dangerName1: '',
      // 企业信息
      infoVisibale: false,
      // 隐患排查详情
      dangerCheckDetail: false,
      companyName: '',
      dialogDetail: false,
      dangerName: '',
      workName: '',
      formData: {}, //风险管控
      formData1: {}, //设备设施
      options2: [
        {
          value: '',
          label: '全部',
        },
        {
          value: 1,
          label: '低风险',
        },
        {
          value: 2,
          label: '一般风险',
        },
        {
          value: 3,
          label: '较大风险',
        },
        {
          value: 4,
          label: '重大风险',
        },
      ],
      value3: '',
      startTime: '',
      currentPage: 1,
      totalCount: 0,
      pageSize: 10,
      tableData: [],
      // 作业活动清单
      currentPage4: 1,
      totalCount4: 0,
      tableData4: [],
      pageSize4: 10,
      formData4: {},
      checkOutId: '',
      // 隐患排查清单
      currentPage6: 1,
      totalCount6: 0,
      tableData6: [],
      pageSize6: 10,
      formData6: {},
      // 巡检记录
      currentPage7: 1,
      totalCount7: 0,
      tableData7: [],
      pageSize7: 10,
      formData7: {},

      // 整改记录列表
      tableDataZheng: [],
      currentPageZheng: 1,
      pageSizeZheng: 10,
      totalCountZheng: 0,
      // 巡检详情
      formDataXun: {},
      dialogDetailXun: false,
      // !巡检详情图片视频
      detailImgList: [],
      detailVideoList: [],
      // 整改记录--巡检图片或视频
      changeVideoCheckList: [],
      changeImgCheckList: [],
      // 整改记录--整改图片或视频
      changeVideoList: [],
      changeImgList: [],
      // 整改记录--复查图片或视频
      changeVideoResetList: [],
      changeImgResetList: [],
      showImgUrl: '',
      showVideoUrl: '',
      imgDia: false,
      videoDia: false,
      dialogHistory: false,
      dialogdh: false,
      formDetailsZheng: {},

      historyId1: '',
      zoom_content: '',
      videoShow: [],
      videoShowL: true,
      imgShowL: true,
      divShow: true,
    }

    //aMap.getCameraPosition().zoom
  },
  methods: {
    beforeDestroy() {
      if (this.player) {
        this.player.dispose()
      }
    },
    // bangding() {
    //   // this.zoomOn
    //   document.getElementById("zoomOn").onclick = this.zoomOn;
    // },
    // mapZoomstart() {
    //   document.querySelector("#text").innerText = '缩放开始';
    // },

    // mapZoom() {
    //   this.logMapinfo();
    //   document.querySelector("#text").innerText = '正在缩放';
    // },

    // mapZoomend() {
    //   document.querySelector("#text").innerText = '缩放结束';
    // },

    // zoomOn() {
    //   // log.success("绑定事件!");
    //   this.map.on('zoomstart', this.mapZoomstart);
    //   this.map.on('zoomchange', this.mapZoom);
    //   this.map.on('zoomend', this.mapZoomend);
    // },
    // 给按钮绑定事件

    // logMapinfo: function() {
    //   var zoom = this.map.getZoom(); //获取当前地图级别
    //   this.zoom_content = this.map.getZoom();
    //   // console.log(zoom)
    //   if (zoom <= 12) {
    //     var judge = document.getElementsByClassName("marker-span");
    //     for (var i = 0; i <= judge.length; i++)
    //       judge[i].style.visibility = "hidden";

    //   } else {
    //     var judge = document.getElementsByClassName("marker-span");
    //     for (var i = 0; i <= judge.length; i++)
    //       judge[i].style.visibility = "visible";
    //   }
    //   // document.querySelector("#map-zoom").innerText = zoom;
    // },

    // 创建地图
    createMap() {
      let that = this
      this.map = new AMap.Map('mapInformation', {
        resizeEnable: true,
        scrollWheel: true,
        zooms: this.zooms,
        zoom: 12,
        center: this.center,
        layers: [new AMap.TileLayer()],
        mapStyle: 'amap://styles/macaron',
        // layers: [
        //             new AMap.TileLayer(),
        //             imageLayer
        //         ]
      })
      // that.map.on(mapZoomstart);

      var opts = {
        subdistrict: 0, //获取边界不需要返回下级行政区
        extensions: 'all', //返回行政区边界坐标组等具体信息
        level: 'district', //查询行政级别为 市
      }
      that.district = new AMap.DistrictSearch(opts)
      console.log(that.district)
      this.district.search(this.loacation, function (status, result) {
        var polygons = []
        var bounds = result.districtList[0].boundaries
        if (bounds) {
          for (var i = 0, l = bounds.length; i < l; i++) {
            //生成行政区划polygon
            var polygon = new AMap.Polygon({
              map: that.map,
              strokeWeight: 1,
              path: bounds[i],
              fillOpacity: 0.1,
              fillColor: '#2eff00',
              strokeColor: '#ff0000',
              strokeStyle: 'dashed',
              strokeWeight: 2,
            })
            polygons.push(polygon)
          }
        }
        that.map.add(polygons)
        that.map.setFitView(polygons) //视口自适应

        var lnglats = that.company

        var markers = []
        for (var i = 0; i < lnglats.length; i++) {
          mouseover: (e) => {
            console.log('移入')
          }
          var lnglat = lnglats[i]
          // console.log(lnglat)
          var lng = parseFloat(lnglat.lng)
          var lat = parseFloat(lnglat.lat)
          var content = lnglat.short_name
          // console.log(content)
          // 创建点实例
          var marker = new AMap.Marker({
            map: that.map,
            position: new AMap.LngLat(lng, lat),
            clickable: true,
            label: {
              offset: new AMap.Pixel(15, 15), //修改label相对于maker的位置
              icon: '/src/assets/images/exit.png',
              // new AMap.Icon({
              //             image: "/src/assets/images/exit.png",
              //             size: new AMap.Size(52, 52),  //图标大小
              //             imageSize: new AMap.Size(26,26)
              //         }),
              // content: content,
            },
            extData: {
              id: i + 1,
            },
          })
          // 自定义点标记内容
          var markerContent = document.createElement('span')
          // 点标记中的图标
          var markerImg = document.createElement('img')
          markerImg.className = 'markerlnglat'
          if (lnglat.level == 1) {
            markerImg.src = 'http://qiniu.sxpz.vip/2021-01-11_5ffc090683b4a.png'
          } else if (lnglat.level == 2) {
            markerImg.src = 'http://qiniu.sxpz.vip/2021-01-11_5ffc091f36b11.png'
          } else if (lnglat.level == 3) {
            markerImg.src = 'http://qiniu.sxpz.vip/2021-01-11_5ffc094628291.png'
          } else if (lnglat.level == 4) {
            markerImg.src = 'http://qiniu.sxpz.vip/2021-01-11_5ffc0931d6794.png'
          }
          markerContent.appendChild(markerImg)
          // 点标记中的文本
          var markerSpan = document.createElement('div')
          markerSpan.className = 'marker-span'
          // markerSpan.innerHTML = content
          markerContent.appendChild(markerSpan)
          marker.setContent(markerContent)
          marker.id = lnglat.id
          marker.is_mine = lnglat.is_mine
          marker.level = lnglat.level
          marker.nickname = lnglat.nickname
          var infoWindow = new AMap.InfoWindow({
            isCustom: false, //使用自定义窗体
            offset: new AMap.Pixel(2.0, -20.0), //窗体偏移位置
            content: content,
            size: 50,
          })
          marker.on('mouseover', function (e) {
            // console.log(e)
            var c = e.target.nickname
            infoWindow.setContent(c)
            infoWindow.open(that.map, e.lnglat)
            // that.map.showInformationMessage();
          })
          marker.on('mouseout', function (e) {
            // console.log(1)
            infoWindow.close()
          })
          marker.on('click', function (e) {
            that.dialogVisible = true
            console.log(e)
            that.center[0] = e.lnglat.lng
            that.center[1] = e.lnglat.lat
            that.id = e.target.id
            that.is_mine = e.target.is_mine
            that.level = e.target.level
            //that.center= new AMap.LngLat(lnglat[0], lnglat[1])
            // var marker = new AMap.Marker({
            //   map: this.map,
            //   position: new AMap.LngLat(lng, lat),
            //   label: {
            //     offset: new AMap.Pixel(10, 10), //修改label相对于maker的位置
            //     content: this.content,
            //   },
            // })
            that.company_marker(that.id, that.is_mine)

            // that.createMap()

            //this.content="<div >天地人面粉</div><img  src='"+require('./16_91_2.png')+"' />"
          })
          markers.push(marker)
        }
        var overlayGroups = new AMap.OverlayGroup(markers)
        that.map.add(overlayGroups)
      })
      // that.bangding()
    },
    imgClick() {
      this.imgDialogVisible = true
      if (this.url == null) return this.$message.error('暂无风险四色图')
    },
    async videoClick() {
      this.videoDialog = true
      this.$nextTick(() => {
        this.videoShow.forEach((item, index) => {
          console.log(index)
          var myVideo = videojs(
            'myVideo' + index,
            {
              bigPlayButton: true,
              textTrackDisplay: false,
              posterImage: false,
              // controls: false,
              errorDisplay: false,
            },
            // console.log(this.myVideo),
            function () {
              this.play()
            }
          )
          this.myVideo.push(myVideo)
        })
        // index=0
      })
      // this.myVideo.dispose()
    },
    dangerList() {
      this.getRiskList()
      this.listDialogVisible = true
    },
    equDangerList() {
      this.getRiskList1()
      this.eqListDialogVisible = true
    },
    workDangerList() {
      this.getWorkList()
      this.WorkListDialogVisible = true
    },
    HiddenDangerList() {
      this.getDangerList()
      this.DanListDialogVisible = true
    },
    XunDangerList() {
      this.getHistoryList()
      this.XunListDialogVisible = true
    },
    handleSizeChange4(val) {
      // console.log(`每页 ${val} 条`)
      this.pageSize4 = val
      this.getWorkList()
    },
    handleCurrentChange4(val) {
      this.currentPage4 = val
      this.getWorkList()
    },
    workDetailClose() {
      this.workDetail = false
    },
    dangerMange() {
      this.MangeDialogVisible = true
    },
    handleSearch() {
      this.currentPage = 1
      this.getRiskList()
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getRiskList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getRiskList()
    },
    handleSizeChange6(val) {
      // console.log(`每页 ${val} 条`)
      this.pageSize6 = val
      this.getDangerList()
    },

    handleCurrentChange6(val) {
      this.currentPage6 = val
      this.getDangerList()
    },

    handleSizeChange7(val) {
      // console.log(`每页 ${val} 条`)
      this.pageSize7 = val
      this.getHistoryList()
    },

    handleCurrentChange7(val) {
      this.currentPage7 = val
      this.getHistoryList()
    },
    toDohandleCurrentChange(val) {
      this.todoCurrentPage = val
      this.getNeedTodoListFirst()
    },
    handleSizeChangeZheng(val) {
      // console.log(`每页 ${val} 条`)
      this.pageSizeZheng = val
      this.getHistoryList1()
    },
    handleCurrentChangeZheng(val) {
      this.currentPageZheng = val
      this.getHistoryList1()
    },
    dialogDetailClose() {
      this.dialogDetail = false
    },
    dialogDetailClose() {
      this.dialogDetail = false
    },
    dangerCheckDetailClose() {
      this.dangerCheckDetail = false
    },
    eqdialogDetailClose() {
      this.eqListDialogVisible_D = false
    },
    closeDialog() {
      this.value3 = '' //清空数据
    },
    dialogDetailXunClose() {
      this.dialogDetailXun = false
    },
    eqcloseDialog() {},
    showVideo(url) {
      this.showVideoUrl = url
      this.videoDia = true
    },
    showPic(url) {
      this.showImgUrl = url
      this.imgDia = true
    },
    imgDiaClose() {
      this.imgDia = false
    },
    closeDialog_Video() {
      console.log(1)
      for (let i = 0; i < this.myVideo.length; i++) {
        console.log('this.myVideo[i]', this.myVideo[i])
        this.myVideo[i].dispose()
      }
      this.myVideo = []
    },
    videoDiaClose() {
      this.videoDia = false
    },
    historyDiaClose() {
      this.currentPageZheng = 1
      this.historyId1 = ''
      this.dialogHistory = false
    },
    dialogdhClose() {
      this.dialogdh = false
    },
    // 获取风险管控清单
    async getRiskList() {
      // return console.log('value', this.value)
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('page', this.currentPage)
      param.append('length', this.pageSize)
      param.append('company_name', this.companyName)
      param.append('danger_name', this.danName)
      param.append('danger_level', this.value3)
      param.append('platform_id', this.platId)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/danger/index',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.tableData = res.datas.list
      this.totalCount = parseInt(res.datas.count)
      console.log('风险点辨识数据', this.tableData)
      console.log('风险点辨识数据条数', this.totalCount)
    },
    async getRiskList1() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('page', this.currentPage)
      param.append('length', this.pageSize)
      param.append('company_name', this.companyName)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/danger/index',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.tableData = res.datas.list
      this.totalCount = parseInt(res.datas.count)
      console.log('风险点辨识数据', this.tableData)
      console.log('风险点辨识数据条数', this.totalCount)
    },
    async getType() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/user/classification',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)

      console.log('企业列表', res)
    },
    //作业活动清单
    async getWorkList() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('page', this.currentPage4)
      param.append('length', this.pageSize4)
      param.append('id', this.id)
      param.append('is_mine', this.is_mine)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/danger_activity/index',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      console.log(res)
      this.tableData4 = res.datas.list
      console.log('作业活动清单', this.tableData4)
      this.totalCount4 = parseInt(res.datas.count)
    },
    // todo点击隐患排查清单(获取数据)
    // todo点击隐患排查清单
    async getDangerList() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('page', this.currentPage6)
      param.append('length', this.pageSize6)
      param.append('id', this.id)
      param.append('is_mine', this.is_mine)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/danger_investigation/index',
        data: param,
      })
      console.log(res)
      if (res.code != 200) return this.$message.error(res.datas.msg)

      this.tableData6 = res.datas.list
      this.totalCount6 = parseInt(res.datas.count)
    },
    // 风险清单详情
    async checkOut(row) {
      this.dangerName = row.danger_name
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', row.id)
      param.append('is_mine', row.is_mine)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/Danger/detail',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.formData = res.datas
      if (this.formData.danger_level == '1') {
        this.formData.danger_level = '低风险'
      }
      if (this.formData.danger_level == '2') {
        this.formData.danger_level = '一般风险'
      }
      if (this.formData.danger_level == '3') {
        this.formData.danger_level = '较大风险'
      }
      if (this.formData.danger_level == '4') {
        this.formData.danger_level = '重大风险'
      }

      this.dialogDetail = true
    },
    // 设备设施详情
    async checkOut1(row) {
      console.log(1)
      console.log(row)
      this.dangerName = row.danger_name
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', row.id)
      param.append('is_mine', row.is_mine)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/Danger/detail',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.formData1 = res.datas
      // if (this.formData1.danger_level == '1') {
      //   this.formData1.danger_level = '低风险'
      // }
      // if (this.formData1.danger_level == '2') {
      //   this.formData1.danger_level = '一般风险'
      // }
      // if (this.formData1.danger_level == '3') {
      //   this.formData1.danger_level = '较大风险'
      // }
      // if (this.formData1.danger_level == '4') {
      //   this.formData1.danger_level = '重大风险'
      // }

      this.eqListDialogVisible_D = true
    },
    // *查看作业详情
    async checkOutWork(row) {
      this.workName = row.danger_accident
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', row.id)
      param.append('is_mine', this.is_mine)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/danger_activity/detail',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.formData4 = res.datas
      console.log('作业活动详情', this.formData4)

      // this.workVisible = false
      this.workDetail = true
    },
    // *查看隐患排查详情
    async handleDangerCheck(row) {
      this.dangerName1 = row.danger_name
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', row.id)
      param.append('is_mine', this.is_mine)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/danger_investigation/detail',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.formData6 = res.datas
      console.log('隐患排查详情', this.formData6)

      this.dangerCheckDetail = true
    },
    // ?点击巡检详情
    async checkOutXun(row) {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', row.id)
      param.append('is_mine', this.is_mine)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/SafetyInspectionRecord/detail',
        data: param,
      })
      console.log('巡检记录详情', res)
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.formDataXun = res.datas
      this.detailImgList = res.datas.inspection_img

      this.detailVideoList = res.datas.inspection_video
      this.dialogDetailXun = true
    },
    // *点击整改记录(获取数据)
    async getHistoryList1() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('length', this.pageSizeZheng)
      param.append('page', this.currentPageZheng)
      param.append('id', this.historyId1)
      // param.append('company_name', this.companyName)
      param.append('is_mine', this.is_mine)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/SafetyInspectionRecord/record',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.tableDataZheng = res.datas.list
      console.log('整改记录', this.tableDataZheng)
      this.totalCountZheng = parseInt(res.datas.count)
    },
    // *点击整改记录
    async changeHistory(id) {
      this.historyId1 = id
      this.getHistoryList1()
      this.dialogHistory = true
    },
    // 点击查看详情(整改记录)
    async checkOutDetails(id) {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', id)
      param.append('is_mine', this.is_mine)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/SafetyInspectionRecord/record_detail',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.formDetailsZheng = res.datas
      console.log('整改记录', this.formDetailsZheng)
      // 巡检图片视频
      this.changeImgCheckList = res.datas.inspection_img
      this.changeVideoCheckList = res.datas.inspection_video
      // 整改图片视频
      this.changeImgList = res.datas.rectify_img
      this.changeVideoList = res.datas.rectify_video
      console.log('整改图片', this.changeImgList)
      // 复查图片视频
      this.changeImgResetList = res.datas.review_img
      console.log('复查图片', this.changeImgResetList)
      this.changeVideoResetList = res.datas.review_video
      this.dialogHistory = false
      this.dialogdh = true
    },
    // 获取企业列表
    async getCompanyList() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('type', this.value)
      param.append('level', this.value1)
      param.append('nickname', this.input)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/RegionalGrid/company_total',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      console.log(res)
      if (res.datas.list.length == 0) this.$message.error('暂无该类型企业')
      this.company = res.datas.list //公司坐标

      console.log(this.company)
      this.count = res.datas.count
      this.level_low = res.datas.level_low
      this.level_general = res.datas.level_general
      this.level_high = res.datas.level_high
      this.level_great = res.datas.level_great
      this.createMap()

      // document.getElementById("zoomOn").onclick = zoomOn;
    },
    // todo点击巡检记录(获取数据)
    async getHistoryList() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('length', this.pageSize7)
      param.append('page', this.currentPage7)
      param.append('id', this.id)
      param.append('is_mine', this.is_mine)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/SafetyInspectionRecord/index',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.tableData7 = res.datas.list
      this.totalCount7 = parseInt(res.datas.count)
      console.log('巡检记录', this.tableData7)
    },
    // 企业坐标点击详情
    async company_marker(id, is_mine) {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', id)
      param.append('is_mine', is_mine)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/RegionalGrid/company_info',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      console.log('企业详细信息')
      console.log(res)
      this.companyName = res.datas.nickname
      this.com_name = res.datas.nickname
      this.com_add = res.datas.address
      this.com_charge_name = res.datas.charge_name
      this.com_charge_mobile = res.datas.charge_mobile
      this.danger_count = res.datas.danger_count
      this.inspection_total = res.datas.inspection_total
      this.inspection_num = res.datas.inspection_num
      this.inspectionone_precent = res.datas.inspectionone_precent
      this.count_low = res.datas.level_low
      this.count_general = res.datas.level_general
      this.count_high = res.datas.level_high
      this.count_great = res.datas.level_great
      this.url = res.datas.file_url
      this.infp = res.datas
      this.videoShow = res.datas.video_url
      if (res.datas.file_url == null) {
        this.imgShowL = false
      }
      if (res.datas.video_url == '') {
        this.videoShowL = false
      }
      if (res.datas.video_url == '' && res.datas.file_url == null) {
        this.divShow = false
      }
      this.videoShowName = res.datas.video_name
      console.log(this.infp)
    },
    needTodoClose() {
      this.needTodoDia = false
    },
    handleTodoClick(val) {
      if (val.index === '0') {
        this.toDoType = '1'
        this.todoCurrentPage = 1
        this.getNeedTodoListFirst()
      } else if (val.index === '1') {
        this.toDoType = '2'
        this.todoCurrentPage = 1
        this.getNeedTodoListFirst()
      } else if (val.index === '2') {
        this.toDoType = '3'
        this.todoCurrentPage = 1
        this.getNeedTodoListFirst()
      }
    },
    async getNeedTodoListFirst() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('type', this.toDoType)
      param.append('page', this.todoCurrentPage)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/index/needToDo',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.zpnum = res.zpnum
      this.zgnum = res.zgnum
      this.fcnum = res.fcnum
      this.todoTotal = parseInt(res.datas.count)
      this.todoList = res.datas.list
      console.log('todoList数据数据', res)
    },
  },
  // destroyed(){
  //   this.myVideo.dispose()
  // },
  created: function () {
    this.token = localStorage.getItem('btlh_token') || ''
    this.userName = localStorage.getItem('btlh_nickname') || ''
    this.loacation = localStorage.getItem('btlh_loacation') || ''
    if (this.$route.path === '/manage/first') {
      this.needTodoDia = true
    }
    this.getNeedTodoListFirst()
    this.getType()
  },
  mounted() {
    this.getCompanyList()
  },
  updated() {
    // this.createMap()
  },
  // deactivated(){
  //   this.myVideo.dispose()
  // }
}
</script>

<style lang="less" scoped>
.select-map {
  height: 97%;
  z-index: 100;

  #mapInformation {
    width: 100%;
    height: 100%;
  }
}

.modifyBtn1 {
  color: #fff;
  background-color: #447ed9 !important;
  width: 50%;
}

.modifyBtn {
  color: #fff;
  background-color: #447ed9 !important;
}

.modifyBtn:nth-child(1) {
  color: #fff;
  background-color: #447ed9 !important;
}

.modifyBtn {
  color: #fff;
  width: 80% !important;
  display: block;
  margin-left: 10px !important;
  background-color: #ec2324 !important;
}

.dialog_border {
  margin-top: -20px !important;
  padding: 10px;
  box-sizing: border-box;
  margin: 0px -5px;
  // box-shadow: 0 0 8px #ddd;
}

.add {
  /deep/ .el-dialog__body {
    padding-top: 10px 20px !important;
    // padding: 0 !important;
  }
}

.el_span {
  margin-right: 40px;
}

.el-row {
  left: 20px;
  font-size: 10px;
  line-height: 30px;
  margin-right: 30px;
  bottom: 1px;
}

.el-col {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.mt_30 {
  width: 100px;
  font-size: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 30px;
  margin-right: 30px;
}

.denger_button {
  font-size: 10px;
}

.bt_father {
  position: relative;
}

.navSelect {
  padding: 5px;
  padding-left: 15px;
  padding-bottom: 10px;
  position: absolute;
  width: 99.5%;
  height: 30px;
  background-color: #ffffff;
  z-index: 100;
}

.list_position {
  position: absolute;
  width: 170px;
  height: 20vh;
  right: 12px;
  top: 60vh;
  z-index: 100;
  // overflow-y: scroll;
}

.btn_list {
  border-radius: 5px;
  outline: 0 none !important;
  // blr: expression(this.onFocus=this.blur());
  margin-top: 5px;
  height: 30px;
  width: 170px;
  background-color: #0ed300;
  color: white;
  border: solid 1px #ffffff;
}

.btn_list1 {
  border-radius: 5px;
  outline: 0 none !important;
  // blr: expression(this.onFocus=this.blur());
  margin-top: 5px;
  height: 30px;
  width: 170px;
  background-color: #ff0000;
  color: white;
  border: solid 1px #ffffff;
}

.btn_list2 {
  border-radius: 5px;
  outline: 0 none !important;
  // blr: expression(this.onFocus=this.blur());
  margin-top: 5px;
  height: 30px;
  width: 170px;
  background-color: #ff5500;
  color: white;
  border: solid 1px #ffffff;
}

.btn_list3 {
  border-radius: 5px;
  outline: 0 none !important;
  // blr: expression(this.onFocus=this.blur());
  margin-top: 5px;
  height: 30px;
  width: 170px;
  background-color: #ffaa00;
  color: white;
  border: solid 1px #ffffff;
}

.btn_list4 {
  border-radius: 5px;
  outline: 0 none !important;
  // blr: expression(this.onFocus=this.blur());
  margin-top: 5px;
  height: 30px;
  width: 170px;
  background-color: #447ed9;
  color: white;
  border: solid 1px #ffffff;
}

.btn_list:hover {
  background-color: #5fd357;
  box-shadow: 10px 10px 5px #888888;
}

.btn_list1:hover {
  background-color: #ff6969;
  box-shadow: 10px 10px 5px #888888;
}

.btn_list2:hover {
  background-color: #ff9e69;
  box-shadow: 10px 10px 5px #888888;
}

.btn_list3:hover {
  background-color: #ffcd69;
  box-shadow: 10px 10px 5px #888888;
}

.btn_list4:hover {
  background-color: #70b9e8;
  box-shadow: 10px 10px 5px #888888;
}

.d {
  overflow-y: scroll;
  height: 500px;
}

.selectRow {
  display: flex;
  font-size: 12px;
  margin-bottom: 15px;

  .el-date-editor.el-input,
  .el-input {
    width: 68%;
  }

  .selectCol {
    margin-right: 10px;
  }

  /deep/ .el-input__inner {
    font-size: 10px;
    height: 26px;
    width: 120px;
    border-radius: 0;
    margin-left: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  /deep/ .el-input__icon {
    display: none !important;
  }

  .checkOutBtn {
    font-size: 12px;
    padding: 5px 20px;
    background-color: #447ed9;
    color: #fff;
  }
}

.tableRow {
  // top: -10px;
  padding: 0 10px;
}

.overflowDoit {
  width: 100%;
  white-space: nowrap; //不换行
  overflow: hidden; //超出隐藏
  text-overflow: ellipsis; //变成...
}

.handleLog {
  padding: 20px;
  background-color: #fff;

  // 标题行
  .titleRow {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;

    .line {
      display: block;
      width: 4px;
      height: 22px;
      margin-right: 10px;
      background-color: #333;
    }
  }

  // 企业选择行
  .selectRow {
    display: flex;
    font-size: 12px;
    margin-bottom: 15px;

    .el-date-editor.el-input,
    .el-input {
      width: 68%;
    }

    .selectCol {
      margin-right: 10px;
    }

    /deep/ .el-input__inner {
      font-size: 10px;
      height: 26px;
      width: 120px;
      border-radius: 0;
      margin-left: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }

    /deep/ .el-input__icon {
      display: none !important;
    }

    .checkOutBtn {
      font-size: 12px;
      padding: 5px 20px;
      background-color: #447ed9;
      color: #fff;
    }
  }

  // 表格
  .tableRow {
    padding: 0 10px;
  }

  /deep/ .el-table {
    margin-top: 0;
    font-size: 12px;

    td {
      padding: 5px 0;
    }

    td,
    th.is-leaf {
      border-bottom: 0;
    }

    th {
      background-color: #f7f7f7;
      color: #222;
      font-weight: 700;
    }

    .el-table--striped .el-table__body tr.el-table__row--striped td {
      background-color: #f7f7f7;
    }
  }

  .el-table::before {
    display: none;
  }

  // 分页
  .el-pagination {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
  }
}
/deep/ .el-input.is-disabled .el-input__inner {
  background-color: #fff !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/deep/ .el-textarea.is-disabled .el-textarea__inner {
  background-color: #fff !important;
  overflow: auto;
  text-overflow: ellipsis;
}

// 超出...的容器
.overflowDoit {
  width: 100%;
  white-space: nowrap; //不换行
  overflow: hidden; //超出隐藏
  text-overflow: ellipsis; //变成...
}

// 修改详情和编辑字体大小
/deep/ .el-form-item__label {
  font-size: 13px !important;
  color: #666 !important;
}

// 修改详情和编辑字体大小
/deep/ .el-input__inner,
/deep/.el-textarea__inner {
  font-size: 12px !important;
  color: #999 !important;
}

.demo-image {
  text-align: center;
}

el-dialog {
  z-index: 1000000000000000000;
}

.videoContainer {
  display: flex;
  flex-wrap: wrap;

  .videoItem {
    height: 600px;
    width: 48%;
    margin-right: 2%;
    margin-bottom: 40px;
  }
}
</style>
<style lang="less">
.add {
  .el-dialog__body {
    padding-top: 0px;
    padding-right: 20px;
    padding-bottom: 30px;
    padding-left: 20px;
  }
}

.cursor {
  cursor: pointer;
}

.marker-span {
  // background-color: #FFFFFF;
  font-size: 0.8px;
  line-height: 12px;
  width: 150px;
  height: 10px;
  margin: 0;
  padding: 0;
  color: #000000;
  font-weight: 700;
  z-index: 999;
  margin-top: -10px;
  margin-left: 30px;
}

// .amap-marker-content{
//   display: flex;
//   width: 300px;
// }
.markerlnglat {
  z-index: 1000000;
  width: 30px;
  height: 30px;
}
.needToDodia {
  .el-dialog {
    margin-top: 10vh !important;
  }
}
</style>
